import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {PAGE_HEIGHT_WITH_NAVBAR, PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER} from "common/helpers";
import React, {useEffect, useState} from 'react';
import '../common/styles/animate.css';
import ChatWindowNavigation from "components/ChatWindowNavigation";
import ChatWindow from "components/Chat/ChatWindow";
import VideoFrameContainer from "containers/VideoFrameContainer";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const MaterialStyles = theme => ({
  mainWrapper: {
    background: `linear-gradient(180deg, rgba(224, 179, 52, 0.2) 0%, rgba(224, 179, 52, 0) 100%)`,
    height: PAGE_HEIGHT_WITH_NAVBAR,
    padding: '30px 0px'
  },
  paper: {
    height: '60vh',
    width: '100%',
  },
  pageWrapper: {
    height: PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER,
    overflowY: 'auto'
  },
  fullScreenChatWrapper:{
    marginTop: 70,
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    },
    overflowY: 'hidden'
  }
});

const ChatFullScreen = ({classes, videoEnabled}) => {
  const showSidebar = videoEnabled;
  const [activeSection, setActiveSection] = useState('chat');
  const chatIsActive = () => activeSection === ChatFullScreen.sections.CHAT;
  const videoIsActive = () => activeSection === ChatFullScreen.sections.VIDEO;

  const location = useLocation();
  const history = useHistory();
  const displayName = useSelector(state => state.profile.displayName);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('section')) {
      setActiveSection(queryParams.get('section'));
      queryParams.delete('section');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [location, history]);

  return (
    <div className={classes.mainWrapper}>
      <Container>
        <Grid container justify="center" spacing={4}>
          {showSidebar &&
            <Grid item xs={12} sm={12} md={2}>
              <ChatWindowNavigation displayName={displayName} onChange={setActiveSection} activeSection={activeSection}/>
            </Grid>
          }
          <Grid item xs={12} sm={12} md={10}>
            <div className={classes.fullScreenChatWrapper}>
              <div hidden={!chatIsActive()}><ChatWindow/></div>
              <div hidden={!videoIsActive()}><VideoFrameContainer isActive={videoIsActive()} /></div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

ChatFullScreen.sections = {
  CHAT: 'chat',
  VIDEO: 'video',
};

export default withStyles(MaterialStyles)(ChatFullScreen);
