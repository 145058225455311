import {find, get} from 'lodash';

const prefix = '[Mentorship Session]';

const actions = {
  GET_MENTORSHIP_CARDS: `${prefix} GET_MENTORSHIP_CARDS`,
  STORE_CARDS: `${prefix} STORE_CARDS`,
  ERROR_FETCHING_CARDS: `${prefix} ERROR_FETCHING_CARDS`,
  STORE_ALL_MENTORSHIP_SESSIONS: `${prefix} STORE_ALL_MENTORSHIP_SESSIONS`,
  STORE_ONGOING_MENTORSHIP_SESSIONS: `${prefix} STORE_ONGOING_MENTORSHIP_SESSIONS`,
  BOOKMARK_SESSION: `${prefix} BOOKMARK_SESSION`,
  STORE_MENTORSHIP: `${prefix} STORE_MENTORSHIP`,
  STORE_MENTORSHIP_PROGRESS: `${prefix} STORE_MENTORSHIP_PROGRESS`,
  STORE_SESSION: `STORE_CURRENT_SESSION`,
  STORE_CURRENT_SESSION_PROGRESS: `${prefix} STORE_CURRENT_SESSION_PROGRESS`,
  STORE_CURRENT_SESSION: `${prefix} STORE_CURRENT_SESSION`,
  RESET_CURRENT_SESSION: `${prefix} RESET_CURRENT_SESSION`,
  SET_CURRENT_STEP_INDEX: `${prefix} SET_CURRENT_STEP_INDEX`,
  SET_CURRENT_STEP_ID: `${prefix} SET_CURRENT_STEP_ID`,
};

const createInitialState = () => (
  {
    mentorshipCards: [],
    loadingCards: false,
    errorLoadingCards: false,
    sessions: {
      allSessions: [],
      ongoingSessions: [],
    },
    partnerDetails: {userDetails:{},partnerDetails:{}},
    progress: {},
    currentSessionCompletedSteps: [],
    currentSessionStepIndex: 0,
    currentStepId: "",
  });

const reducer = (state = createInitialState(), action) => {
  switch (action.type) {
    case actions.GET_MENTORSHIP_CARDS: {
      return {
        ...state,
        loadingCards: true,
      };
    }
    case actions.STORE_CARDS: {
      return {
        ...state,
        mentorshipCards: action.payload,
        loadingCards: false
      };
    }
    case actions.ERROR_FETCHING_CARDS: {
      return {
        ...state,
        errorLoadingCards: true,
        loadingCards: false
      };
    }
    case actions.SET_CURRENT_STEP_INDEX: {
      return {
        ...state,
        currentSessionStepIndex: action.payload,
      };
    }
    case actions.SET_CURRENT_STEP_ID: {
      return {
        ...state,
        currentStepId: action.payload,
      };
    }
    case actions.STORE_ALL_MENTORSHIP_SESSIONS: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          allSessions: action.payload,
        }
      };
    }
    case actions.STORE_ONGOING_MENTORSHIP_SESSIONS: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          ongoingSessions: action.payload || [],
        }
      };
    }
    case actions.BOOKMARK_SESSION: {
      const sessionId = action.payload;
      const allSessions = state.sessions.allSessions.map((session) => ({
        ...session,
        isBookmarked: sessionId === session.id ? !session.isBookmarked : session.isBookmarked,
      }));

      const ongoingSessions = state.sessions.ongoingSessions.map((session) => ({
        ...session,
        isBookmarked: sessionId === session.id ? !session.isBookmarked : session.isBookmarked,
      }));

      return {
        ...state,
        sessions: {
          allSessions,
          ongoingSessions,
        }
      };
    }
    case actions.STORE_CURRENT_SESSION: {
      return {
        ...state,
        currentSession: action.payload,
      };
    }
    case actions.RESET_CURRENT_SESSION: {
      return {
        ...state,
        currentSession: {sessionSteps: []},
      };
    }
    case actions.STORE_MENTORSHIP: {
      return {
        ...state,
        partnerDetails: action.payload,
      };
    }
    case actions.STORE_MENTORSHIP_PROGRESS: {
      return {
        ...state,
        progress: action.payload,
      };
    }
    case actions.STORE_CURRENT_SESSION_PROGRESS: {
      return {
        ...state,
        currentMentorshipSessionProgress: action.payload,
      };
    }
    case actions.STORE_SESSION: {
      const currentSession = find(state.sessions.ongoingSessions,
        (session) => session.id === action.payload.id);

      const currentSessionCompletedSteps = get(currentSession, 'completedSteps');
      const currentSessionPartnerCompletedSteps = get(currentSession, 'partnerCompletedSteps');

      return {
        ...state,
        currentSessionCompletedSteps,
        currentSessionPartnerCompletedSteps,
      };
    }
    default: {
      return state;
    }
  }
};

export {actions, reducer};
