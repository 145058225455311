import withStyles from "@material-ui/core/styles/withStyles";
import React, {useCallback, useEffect, useState, useRef} from "react";
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useTranslation} from "react-i18next";
import ChatMemberList from "components/Chat/ChatMemberList";
import ChatMessageWindow from "components/Chat/ChatMessageWindow";
import { debounce, isEmpty, isNumber, sum } from 'lodash';
import {getChatInstance} from "chat/WebSocket";
import {getChannelFor} from "business/channel";
import ChatFloatingButton from "components/Chat/ChatFloatingButton";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {history} from "common/history";
import useTheme from "@material-ui/core/styles/useTheme";
import IconButton from "@material-ui/core/IconButton/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import ChatSearchResult from "./ChatSearchResult";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputBase from "@material-ui/core/InputBase/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import { searchChannelsByName } from "../../../src/common/helpers"
import { searchMessages } from "../../../src/api/chatApi"
import { silentApiAction } from "../../../src/actions/actionWrappers"

const styles = theme => ({
  wrapper: {
    position: 'absolute',
    bottom: 0,
    right: 24,
    width: 320,
    zIndex:'99',
  },
  titleBarText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
  },
  unreadMessageCountTitleText: {
    backgroundColor: '#E0B334',
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '2px 6px',
    borderRadius: '50%',
  },
  unreadTaggedMessage: {
    backgroundColor: '#E0B334',
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '2px 4px',
    borderRadius: '50%',
    marginRight: 4,
  },
  iconAdjust: {
    transform: 'rotate(180deg)',
    color: '#010101'
  },
  titleBar: {
    padding:'0px 16px',
    borderBottom: '0.5px solid rgba(0, 0, 0, 0.15)',
  },
  expandedTitleBar: {
    marginTop: '0px !important',
    marginBottom: '0px !important',
    minHeight: '45px !important',
    alignItems:'center',
  },
  listContainer:{
    padding:0,
    minHeight: 300
  },
  tabletViewFloatingButtonWrapper: {
    position: 'absolute',
    right: 12,
    bottom: 16
  },
  closeBtnStyling: {
    padding: 0
  },
  clearIcon: {
    padding: 0,
    marginRight: 10,
    fontSize:  10,
    color: 'rgba(0, 0, 0, 0.22)',
    fontStyle: 'italic',
  },
  backIcon: {
    padding: 0,
    marginRight: 10,
    color: '#000000',
  },
  textFieldWrapper: {
    width: '100%',
    padding: 0,
    backgroundColor: '#FEFCF5',
    borderRadius: 8,
    maxHeight: 58,
  },
  searchBlock: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  "@global": {
    ".MuiAccordionSummary-root.Mui-focusVisible": {
      backgroundColor: 'transparent',
    },
  },
  searchIconWrapper: {
    flexShrink: 0,
    padding: 0,
    marginLeft: 10,
    marginRight:0,
  },
  searchIcon: {
    height: 20,
    with: 20,
    color: 'rgba(28, 27, 31, 0.4)',
  },
});

const Messenger = ({
  classes,
  getChatChannels,
  chatChannels,
  myId,
  messageWindowActive,
  toggleMessageWindow,
  selectedChannelId,
  myEmail,
  addNewMessageToChannel,
  addMessages,
  messages,
  channelUnreadCount,
  fetchingList,
  nextPageNumber,
  isChatActive,
  channelsWithMemberId,
  getChatBlackoutPeriod,
  chatBlackoutPeriod,
  updateUnreadCount,
  updateChannelMemberLastViewedTime,
  getChatBotUsers,
  botUsers,
  updateUserChatRating,
  getChatRatingModalStatus,
  chatRatingStatus,
}) => {
  const {t} = useTranslation();

  const [isChatWindowExpanded, setIsChatWindowExpanded] = useState(false);
  const chatInstance = getChatInstance();
  const tenant = 'mentortogether';
  const theme = useTheme();
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false)
  const [searchedChannels, setSearchedChannels] = useState([])
  const [searchedText, setSearchedText] = useState("");
  const [isSearched, setIsSearched] = useState(false)
  const searchedSelectedChannel = null
  const inputRef = useRef(null);
  const [searchMessageResult, setSearchedMessageResult] = useState([])
  const [searchResultNextPageNumber, setSearchResultNextPageNumber] = useState(2)
  const [hasMoreSearchMessageResult, setHasMoreSearchMessageResult] = useState(true)
  const [searchedMessageText, setSearchedMessageText] = useState()
  const [selectedMessageId, setSelectedMessageId] = useState(null)
  const [selectedTab, setSelectedTab] = useState('')
  const [isMessageSelected, setIsMessageSelected] = useState(false)

  useEffect(() => {
    chatInstance.addCallbacks((messages, nextPage, type) => addMessages(messages, nextPage, type),
      (message) => addNewMessageToChannel(message), (message) => updateUnreadCount(message),
      (channelMemberData) => updateChannelMemberLastViewedTime(channelMemberData));
    chatInstance.addCallback('reload', debounce(() => {console.log('reloading channels'); getChatChannels();}, 1000));
  }, [addNewMessageToChannel, myId, addMessages, messages, chatInstance]);

  useEffect(() => {
    getChatChannels();
  }, [getChatChannels]);

  useEffect(() => {
    isEmpty(botUsers) && getChatBotUsers();
  }, [chatChannels]);

  useEffect( ()=>{
    !isEmpty(chatChannels) && chatInstance.connect(tenant, chatChannels);
  }, [chatInstance, tenant, chatChannels]);

  const channelsUnread = sum(channelUnreadCount.map(myUnread => myUnread.count));
  const isUserTaggedInUnreadMessage = channelUnreadCount.filter(myUnread => myUnread.count > 0 && myUnread.data?.is_tagged);

  const sendMessage = useCallback(async (message) => {
    await chatInstance.newChatMessage(message);
  }, [chatInstance]);

  const viewMessage = useCallback((channelId) => {
    chatInstance.onMemberMessageSeen(channelId)
  }, [chatInstance])

  const clearMessage = () => {
    setSearchedText("");
    if(searchedChannels.length === 0) {
      setIsSearched(false)
    }
  }

  const handleTextInput = (value) => {
    setSearchedText(value)
  };

  const handleBack = () => {
    setIsSearchBoxOpen(false);
    setSearchedChannels([]);
    setIsSearched(false)
    setSearchedText('')
    setSearchedMessageResult([])
  }

  const searchChannels = (text) => {
    const result = searchChannelsByName(chatChannels, text, myId);
    setSearchedChannels(result);
  }

  const searchChatMessages = async (text, pageNumber, newSearch) => {
    setSearchedMessageText(text)
    try {
      const searchedMessages = await silentApiAction(searchMessages, text, pageNumber)();
      setHasMoreSearchMessageResult(searchedMessages.hasNextPage)
      if (newSearch) {
        setSearchedMessageResult(searchedMessages.messages)
      } else {
        setSearchedMessageResult(prevMessages => [...prevMessages, ...searchedMessages.messages]);
      }
    } catch (error) {
      console.error('Error fetching searched messages:', error);
    }
  }

  const onSearch = async (text) => {
    setSearchedChannels([]);
    searchChannels(text);
    setIsSearched(true)
    setSearchResultNextPageNumber(1);
    await searchChatMessages(text, 1, true);
  };

  const loadMoreSearchMessages = async () => {
    const nextPage = searchResultNextPageNumber + 1;
    setSearchResultNextPageNumber(nextPage);
    await searchChatMessages(searchedText, nextPage, false);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      const trimmedText = searchedText.trim();
        if (trimmedText !== '') {
          onSearch(trimmedText);
        }
      event.preventDefault()
    }
  };

  const connectWithChannel = useCallback((channelId, nextPageNumber, messageId = null) => {
    toggleMessageWindow(true, channelId, messageId);
    chatInstance.fetchMessagesForChannel(channelId, nextPageNumber, messageId);
  }, [chatInstance, toggleMessageWindow]);

  const disConnectWithChannel = async(id) => {
    await chatInstance.leaveChannel(id);
    toggleMessageWindow(false, id)
  };

  const toggleChannelConnection = () => {
    isChatWindowExpanded ?
      isNumber(selectedChannelId) && chatInstance.leaveChannel(selectedChannelId) :
      isNumber(selectedChannelId) && chatInstance.fetchMessagesForChannel(selectedChannelId, 1);
  }

  if (!isChatActive || chatChannels.length === 0) {
    return (<></>);
  }

  if (isTabletView) {
    return <div className={classes.tabletViewFloatingButtonWrapper}>
      <ChatFloatingButton onClick={() => history.push('/chat/messenger')}/>
    </div>
  }

  const handleClick = () => {
    setIsSearchBoxOpen(true)
  }

  return (
    <div className={classes.wrapper}>
      <Accordion
        square={true}
        expanded={isChatWindowExpanded}
      >
        {(!messageWindowActive || !isChatWindowExpanded) &&
        <AccordionSummary
          onClick={() => {toggleChannelConnection();
            !isChatWindowExpanded && setIsChatWindowExpanded(!isChatWindowExpanded)
          }}
          expandIcon={<ExpandMoreIcon
            classes={{root: classes.iconAdjust}}
            onClick={() => {toggleChannelConnection();
              setIsChatWindowExpanded(!isChatWindowExpanded)
            }}
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{root: classes.titleBar, expanded: classes.expandedTitleBar}}
        >
          {isChatWindowExpanded ?
            <>
            { isSearchBoxOpen ?
              <div className={classes.searchBlock}>
                <IconButton classes={{root: classes.backIcon}} onClick={handleBack}>
                  <ArrowBackIcon/>
                </IconButton>
                <div className={classes.textFieldWrapper}>
                  <InputBase
                    style={{ padding: 8, backgroundColor: '#FEFCF5', color: '#000000', borderRadius: 8}}
                    inputRef={inputRef}
                    placeholder={t('Type and search')}
                    fullWidth
                    autoFocus
                    value={searchedText}
                    onChange={(e) => handleTextInput(e.target.value)}
                    onKeyDown={handleKeyPress}
                    endAdornment={
                      <InputAdornment position="end">
                        {searchedText && (
                          <IconButton edge="end" classes={{root: classes.clearIcon}} onClick={clearMessage} tabIndex="-1">
                            clear
                          </IconButton>
                        )}
                        <SearchIcon
                          style={{ cursor: searchedText.trim() === '' ? 'not-allowed' : 'pointer', color: '#E0B334', width: 20, height: 20 }}
                          onClick={() => {
                            const trimmedText = searchedText.trim();
                            if (trimmedText !== '') {
                              onSearch(trimmedText);
                            }
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </div>
              </div>
            :
            <div className={classes.titleBarText}> {t("Messages")}
              <IconButton classes={{root: classes.searchIconWrapper}} edge="end" onClick={handleClick}>
                  <SearchIcon classes={{root: classes.searchIcon}}/>
                </IconButton>
            </div>
            }
            </>
            :
            <div className={classes.titleBarText}>
            <span style={{ marginRight: 18 }}>{t("Messages")}</span>
            {channelsUnread > 0 && isUserTaggedInUnreadMessage.length > 0 &&
            <span className={classes.unreadTaggedMessage}>@</span>
            }
            {channelsUnread > 0 &&
            <span className={classes.unreadMessageCountTitleText}>{channelsUnread}</span>
            }
          </div>
          }
        </AccordionSummary>
        }
        <AccordionDetails
          classes={{root:classes.listContainer}}
        >
          <div style={{ width: 320, overflowX: 'scroll', maxHeight: 500 }}>
            {(messageWindowActive && isChatWindowExpanded) ?
              <ChatMessageWindow
                onBackPress={async () => await disConnectWithChannel(selectedChannelId)}
                showBackBtn={true}
                myId={myId}
                myEmail={myEmail}
                messages={messages}
                onSendClick={sendMessage}
                hasMore={nextPageNumber !== null}
                loadMoreMessages={async () => await chatInstance.fetchMessagesForChannel(selectedChannelId, nextPageNumber)}
                onExpandBtnPress={() => {toggleChannelConnection(); setIsChatWindowExpanded(!isChatWindowExpanded)}}
                channel={getChannelFor(selectedChannelId, chatChannels)}
                onChatMemberClick={(id) => connectWithChannel(id, nextPageNumber)}
                channelsWithMemberId={channelsWithMemberId}
                getChatBlackoutPeriod={getChatBlackoutPeriod}
                chatBlackoutPeriod={chatBlackoutPeriod}
                onViewMessage={viewMessage}
                addNewMessageToChannel={addNewMessageToChannel}
                chatChannels={chatChannels}
                selectedChannelId={selectedChannelId}
                selectedMessageId={selectedTab === 'messages' && isSearched ? selectedMessageId : undefined}
                setSelectedMessageId={setSelectedMessageId}
                setIsMessageSelected={setIsMessageSelected}
                isMessageSelected={isMessageSelected}
                setSearchedMessageResult={setSearchedMessageResult}
                botUsers={botUsers}
                updateUserChatRating={updateUserChatRating}
                getChatRatingModalStatus={getChatRatingModalStatus}
                chatRatingStatus={chatRatingStatus}
              /> :
              <>
              {isSearchBoxOpen
                ? <ChatSearchResult
                searchedChannels={searchedChannels}
                onItemClick={(channelId, nextPageNumber, messageId) => {
                  connectWithChannel(channelId, nextPageNumber, messageId);
                  setIsMessageSelected(true)
                }}
                myId={myId}
                isSearched={isSearched}
                searchedChannelsFound={searchedChannels.length > 0}
                selectedChannelId={searchedSelectedChannel}
                searchMessageResult={searchMessageResult}
                searchedMessagesFound={searchMessageResult.length > 0}
                loadMoreSearchMessages={loadMoreSearchMessages}
                hasMoreSearchMessageResult={hasMoreSearchMessageResult}
                searchedMessageText={searchedMessageText}
                myEmail={myEmail}
                setSelectedMessageId={setSelectedMessageId}
                selectedMessageId={selectedMessageId}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                isMobile={true}
                chatChannels={chatChannels}
                nextPageNumber={nextPageNumber}
              />
                : <ChatMemberList
                  chatChannels={chatChannels}
                  channelUnreadCount={channelUnreadCount}
                  myId={myId}
                  selectedChannelId={selectedChannelId}
                  isLoading={fetchingList}
                  onItemClick={(id) => connectWithChannel(id, 1)}
                  botUsers={botUsers}
                />
              }
              </>
            }
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default withStyles(styles)(Messenger);
