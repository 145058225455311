import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import {get, map} from 'lodash';
import Avatar from "@material-ui/core/Avatar/Avatar";
import {useTranslation} from "react-i18next";
import ChatIcon from "common/assets/Chat.svg";

const styles = theme => ({
  ListMemberWrapper: {
    display: 'flex',
    borderBottom: '0.5px solid rgba(0, 0, 0, 0.15)',
    padding: '12px 16px',
    alignItems: 'center',
  },
  wrapper: {
    background: '#E0B334',
    borderTop: '0.5px solid rgba(0, 0, 0, 0.15)',
    width: 300,
    minWidth: '60%'
  },
  mediumAvatar: {
    width: 48,
    height: 48
  },
  name: {
    marginLeft: 12,
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  closeBtn: {
    fontWeight: 'bold',
    color: '#fff',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 102,
    cursor: 'pointer',
  }

});


const GroupMemberList = ({
                           classes, members, onClose, myId, onChatClick
                         }) => {

  const {t} = useTranslation();

  const MemberItem = ({member, isSelf}) =>
    <div className={classes.ListMemberWrapper}>
      <div>
        <Avatar alt={get(member, "userFullName")} src={get(member, 'displayPicture')}
                className={classes.mediumAvatar}/>
      </div>

      <div
        className={classes.name}>
        {get(member, "userFullName")}
      </div>
      {(!isSelf) &&
      <div style={{marginLeft: 'auto', marginRight: 16, cursor: 'pointer'}}
           onClick={() => onChatClick(member.userId)}>
        <img src={ChatIcon} alt={''}/>
      </div>
      }
    </div>;

  return (
    <div className={classes.wrapper}>
      {map(members, member => <MemberItem member={member} isSelf={member.userId === myId}/>)}
      <div className={classes.closeBtn} onClick={onClose}>{t("CLOSE")}</div>
    </div>
  );
};

export default withStyles(styles)(GroupMemberList);


GroupMemberList.propTypes = {};

GroupMemberList.defaultProps = {};
