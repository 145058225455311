import {initSessions, toggleBookmark} from "actions/sessionAction";
import {get} from 'lodash';
import {connect} from 'react-redux';
import {setCurrentPath} from "reducers/NavmenuReducer";
import LearningHub from "views/LearningHub";


const mapStateToProps = ({profile: {displayName}, auth}) => ({
  displayName,
  mentorshipSessionStatus: get(auth, 'user.status.experiences.mentorshipSessionStatus'),
});

export default connect(mapStateToProps, {initSessions, toggleBookmark, setCurrentPath})(LearningHub);
