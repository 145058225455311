import request from "api/request";

export const startNewVideo = (channelId, auth) => request({
  url: `api/v2/video/room/`,
  method: 'POST',
  data: {channel: channelId},
  auth
});

export const logVideoRoomEvent = (roomId, event, jitsiParticipantId, timestamp, auth) => request({
  url: `api/v2/video/room/${roomId}/event/`,
  method: 'POST',
  data: {event, jitsiParticipantId, timestamp},
  auth
});

export const getRoomDetails = (roomId, auth) => request({
  url: `api/v2/video/room/${roomId}/`,
  auth
});

export const submitConsent = (roomId, consent, auth) => request({
  url: `api/v2/video/room/${roomId}/consent/`,
  method: 'POST',
  auth,
  data: {consent},
});

