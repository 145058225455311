import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {PAGE_HEIGHT_WITH_NAVBAR} from "common/helpers";
import MatchingHistoryModal from "components/MatchingHistoryModal";
import MatchingNavigation from "components/navigation/MatchingNavigation";
import React, {useEffect, useState} from 'react';
import MatchingContentWrapper from "views/Matching/MatchingContentWrapper";
import '../../common/styles/animate.css';
import {useTranslation} from "react-i18next";


const MaterialStyles = theme => ({
  mainWrapper: {
    background: `linear-gradient(180deg, #FFEFE9  0%, rgba(255, 239, 233, 0) 100%)`,
    height: PAGE_HEIGHT_WITH_NAVBAR,
    padding: '30px 0px',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px',
      overflowX: 'hidden',
    },
  },
  matchingHistory: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    padding: 10,
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '14px',
    color: '#F89A7A',
    display: 'flex',
    flexWrap: 'nowrap',
    width: '170px',
    cursor: 'pointer',
    marginTop: 6,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  }
})

const ActiveMatching = ({classes, updateMatch, getMatchesList, expiryHours, getActiveMatches, submitMatchesResponse, submitMatch, screen, displayName, activeMatches, screensList, matches, role, groupInfo,userMatchType,setCongratulationsState,showGroupHoldCongratulationDialog}) => {

  useEffect(() => {
    getActiveMatches()
  }, []);

  const [open, setOpen] = useState(false)
  const handleState = () => setOpen(!Boolean(open))
  const {t} = useTranslation();


  return (
    <div className={classes.mainWrapper}>
      <Container disableGutters>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} sm={4} md={2}>
            <div style={{position:'sticky',top:0}}>
              <MatchingNavigation screen={screen} displayName={displayName} role={role}/>
              <div className={classes.matchingHistory} onClick={handleState}>{t("viewMatchingHistory")}</div>
              <MatchingHistoryModal handleState={handleState} open={open} getMatchesList={getMatchesList}
                                    matches={matches}/>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <MatchingContentWrapper
              screen={screen}
              activeMatches={activeMatches}
              role={role}
              submitMatch={submitMatch}
              updateMatch={updateMatch}
              expiryHours={expiryHours}
              groupInfo={groupInfo}
              submitMatchesResponse={submitMatchesResponse}
              userMatchType={userMatchType}
              setCongratulationsState={setCongratulationsState}
              showGroupHoldCongratulationDialog={showGroupHoldCongratulationDialog}
            screensList={screensList}/>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default withStyles(MaterialStyles)(ActiveMatching)
